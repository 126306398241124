import React, { useState } from 'react';
import styled from 'styled-components';
import rangeParser from 'parse-numeric-range';
import Highlight, { defaultProps } from 'prism-react-renderer';
import Confetti from 'react-dom-confetti';

import './style.css';

const Pre = styled.pre`
  font-size: 17px;
  line-height: 20px;
  text-align: left;
  margin: 2em 0;
  padding: 0.5em;
  overflow: scroll;
`;

const Line = styled.div``;

const LineNo = styled.span`
  text-align: right;
  padding-left: 0.5em;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span``;

const CodeBlockWrapper = styled.div`
  width: 100%;
  margin: 16px 0;
  background-color: #282A36;
  overflow: auto;

  &.gatsby-highlight > pre[class*='language-'] 
  {
    background-color: transparent;
    float: left;
    min-width: 100%;
  }
`;

const config = {
  angle: 90,
  spread: 90,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}


const Wrapper = (props) => <div style={{ position: 'relative' }} {...props} />;

const ConfettiWrapper = (props) => <div style={{ position: 'absolute', top: 0, right: 0 }} {...props} />;

const Button = (props) => (
  <button
    style={{
      border: 'none',
      boxShadow: 'none',
      textDecoration: 'none',
      margin: '8px 16px 8px 8px',
      padding: '8px 12px',
      background: '#E2E8F022',
      cursor: 'pointer',
      color: '#FFFFFF',
      fontSize: '14px',
      fontFamily: 'sans-serif',
      lineHeight: '1',
      position: 'absolute',
      top: 0,
      right: 0
    }}
    {...props}
  />
);

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const calculateLinesToHighlight = (meta) => {
  const RE = /{([\d,-]+)}/;
  if (RE.test(meta)) {
    const strlineNumbers = RE.exec(meta)[1];
    const lineNumbers = rangeParser(strlineNumbers);
    return (index) => lineNumbers.includes(index + 1);
  } else {
    return () => false;
  }
};

export default ({ children, className, metastring }) => {
  const [isCopied, setIsCopied] = useState(false);
  // Pull the className
  const language = className.replace(/language-/, '') || '';
  const shouldHighlightLine = calculateLinesToHighlight(metastring);
  const codeString = children.trim();

  return (
    <Wrapper>
      <CodeBlockWrapper>
      <Highlight {...defaultProps} theme={undefined} code={codeString} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre
            className={className}
            style={{
              ...style,
              padding: '1rem',
            }}
          >
            <Button
              onClick={() => {
                copyToClipboard(codeString);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000);
              }}
            >
              {isCopied ? '🎉 Copied!' : 'Copy'}
            </Button>
            {tokens.map((line, i) => {
              const lineProps = getLineProps({ line, key: i });
              if (shouldHighlightLine(i)) {
                lineProps.className = `${lineProps.className} highlight-line`;
              }
              return (
                <Line key={i} {...lineProps}>
                  <LineNo>{i + 1}</LineNo>
                  <LineContent>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </LineContent>
                </Line>
              );
            })}
          </Pre>
        )}
      </Highlight>
      </CodeBlockWrapper>
      <ConfettiWrapper>
        <Confetti active={isCopied} config={config} />
      </ConfettiWrapper>
    </Wrapper>
  );
};
