import styled from 'styled-components';
import tw from 'twin.macro';

export const Footer = styled.footer`
  ${tw`flex flex-wrap flex-col bg-white border border-gray-200`};
`;

export const SectionTitle = styled.p`
  ${tw`text-base text-gray-600 font-bold px-2 mb-2`}
`

export const Links = styled.div`
  ${tw`flex flex-col text-base w-full sm:w-1/3 mx-auto my-2`};

  a {
    ${tw`text-base text-gray-900 hover:text-indigo-600 px-2`};
  }
`;

export const Link = styled.a`
  ${tw`text-base text-gray-900 hover:text-indigo-600 px-2`};
`;

export const Copyrights = styled.div`
  ${tw`flex text-sm items-center justify-center w-full border border-gray-200 p-2`}
`
