import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { MDXProvider } from '@mdx-js/react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CodeBlock from 'components/ui/CodeBlock';
import CodeLink from 'components/ui/CodeLink';
import TocContainer from 'components/ui/TableOfContent';

import 'assets/styles/global.css';
import GlobalStyles from 'assets/styles/globalStyles';
import * as Styled from './styles';


const components = {
  code: CodeBlock,
  CodeLink,
  TocContainer
};


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <MDXProvider components={components}>
      <>
        <GlobalStyles />
        <AnimatePresence exitBeforeEnter>
          <Styled.Layout>
            <Header siteTitle={data.site.siteMetadata.title} />
            <motion.div
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.2 }}
            >
              {children}
              <Footer />
            </motion.div>
          </Styled.Layout>
        </AnimatePresence>
      </>
    </MDXProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired
};

export default Layout;
