import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`flex flex-wrap max-w-screen-lg w-full mx-auto p-8`};
  ${({ section }) => section && tw`max-w-screen-lg p-8`};
`;

export const HeaderContainer = styled.div`
  ${tw`flex flex-wrap max-w-screen-lg w-full mx-auto p-5`};
`;

export const FeaturesContainer = styled.div`
  ${tw`flex flex-wrap mx-auto px-4`};
`;