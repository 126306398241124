import React from 'react';

import * as Styled from './styles';

const CodeLink = ({ url }) => (
  <Styled.CodeLinkContainer>
    <Styled.CodeLinkItem href={url} target="_blank">
      <button
        className="bg-purple-600 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
        type="button"
        style={{ transition: 'all .15s ease' }}
      >
        Run
      </button>
    </Styled.CodeLinkItem>
  </Styled.CodeLinkContainer>
);

export default CodeLink;
