import styled from 'styled-components';
import tw from 'twin.macro';

export const CodeLinkContainer = styled.div`
  ${tw`flex flex-row w-full my-4 justify-end`}
`;

export const CodeLinkTitle = styled.div`
  ${tw`mr-1 mb-1`}
`;

export const CodeLinkItem = styled.a`
  ${tw`text-center text-purple-600 mx-1`}
`;
