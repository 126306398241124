import React from 'react';

import Container from 'components/ui/Container';

import * as Styled from './styles';

const Footer = () => (
  <Styled.Footer>
    <Container section>
      <Styled.Links>
        <Styled.SectionTitle>Links</Styled.SectionTitle>
        <Styled.Link href="/blog" rel="noreferrer noopener" target="_self">
          Blog
        </Styled.Link>
        <Styled.Link href="/serverless" rel="noreferrer noopener" target="_self">
          Serverless
        </Styled.Link>
        <Styled.Link href="/updates" rel="noreferrer noopener" target="_self">
          Updates
        </Styled.Link>
        <Styled.Link href="https://nextpage.devsaurus.com" rel="noreferrer noopener" target="_blank">
          Platform
        </Styled.Link>
        <Styled.Link href="/faq" rel="noreferrer noopener" target="_self">
          FAQ
        </Styled.Link>
        <Styled.Link href="/privacy" rel="noreferrer noopener" target="_self">
          Kebijakan Privasi
        </Styled.Link>
      </Styled.Links>
      <Styled.Links>
        <Styled.SectionTitle>Media Sosial</Styled.SectionTitle>
        <Styled.Link href="https://instagram.com/devsaurus_class" rel="noreferrer noopener" target="_blank">
          Instagram
        </Styled.Link>
        <Styled.Link href="https://medium.com/devsaurus-class" rel="noreferrer noopener" target="_blank">
          Medium
        </Styled.Link>
      </Styled.Links>
      <Styled.Links>
        <Styled.SectionTitle>Open Source</Styled.SectionTitle>
        <Styled.Link href="https://github.com/devsaurus-class" rel="noreferrer noopener" target="_blank">
          GitHub
        </Styled.Link>
      </Styled.Links>

    </Container>
    <Styled.Copyrights>
        <p>© 2020-2021 Devsaurus Class</p>
      </Styled.Copyrights>
  </Styled.Footer>
);

export default Footer;
