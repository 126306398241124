import React, { useState } from 'react';

import * as Styled from './styles';

const mainNavItems = [
  {
    title: 'Home',
    type: 'internal',
    slug: '/'
  },
  {
    title: 'Belajar Coding',
    type: 'internal',
    slug: '/belajar-coding/'
  },
  {
    title: 'Ebook',
    type: 'external',
    slug: 'https://gate-lp.devsaurus.com/'
  }
];

const MainNav = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Styled.MainNav open={open}>
        {mainNavItems.map((item, index) => {
          if (item.type === 'external') {
            return (
              <Styled.MainNavItemExternal
                key={`nav-item-${index}`}
                href={item.slug}
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
              >
                {item.title}
              </Styled.MainNavItemExternal>
            );
          } else {
            return (
              <Styled.MainNavItem
                key={`nav-item-${index}`}
                to={item.slug}
                activeClassName="active"
                whileTap={{ scale: 0.9 }}
              >
                {item.title}
              </Styled.MainNavItem>
            );
          }
        })}
      </Styled.MainNav>
      <Styled.ToogleMainNav open={open} onClick={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </Styled.ToogleMainNav>
    </>
  );
};

export default MainNav;
