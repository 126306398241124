import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';

export default createGlobalStyle`
  body {
    ${tw`m-0 font-opensans text-lg text-gray-900 leading-relaxed tracking-wide bg-white`};
  }

  a {
    ${tw`text-purple-600 hover:text-purple-800`};
  }
  
  p + p {
    ${tw`mt-5 mb-5`};
  }
`;
